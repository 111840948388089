export const NAME = "Mannvender Singh";
export const HI = "Hi!";
export const IM = "I'm";
export const AGE = "23 + 4 years old";
export const INTRO_HELLO = "H E L L O!!!";
export const INTRO_HEADING = "I'm Web Developer in New Delhi, India.";
export const INTRO_DESCRIPTION = `I love Programming and never stop searching for
								  challenging tasks and new form of self-expression to improve my skills.`;
export const DEVELOPER = "DEVELOPER";

// Section Headings
export const SKILLS_SECTION_HEADING = "Skills";
export const LEARNING_SECTION_HEADING = "Learning Experience";
export const RATING_SECTION_HEADING = "Skill-O-Meter";
export const HOBBY_SECTION_HEADING = "Hobby";
export const CONTACT_SECTION_HEADING = "Contact";

// Skills
export const DATA_STRUCTURES = "Data-Structures";
export const ALGORITHMS = "Algorithms";
export const JAVASCRIPT = "JavaScript";
export const REACT = "ReactJS";
export const NODE = "NodeJS";
export const GIT = "Git";
export const MONGO_DB = "MongoDB";
export const C = "C";
export const HTML_CSS = "HTML/CSS";
export const TYPESCRIPT = "TypeScript";

// Timeline
export const CODING_BLOCKS = "Coding Blocks";
export const CODING_BLOCKS_DESCRIPTION = "Intoduced me to programming";
export const CODING_BLOCKS_FROM = "SEP 16";
export const CODING_BLOCKS_TO = "DEC 17";
export const SG_EDUTECH = "SG Edutech Pvt Ltd";
export const SG_EDUTECH_DESCRIPTION = "Interned as Web Developer";
export const SG_EDUTECH_FROM = "JUNE 18";
export const SG_EDUTECH_TO = "AUG 18";
export const EDUATLAS = "Eduatlas";
export const EDUATLAS_DESCRIPTION = "Full Stack Web Developer/ Co-Founder";
export const EDUATLAS_FROM = "AUG 18";
export const EDUATLAS_TO = "JULY 19";
export const FREELANCING = "Professional Freelancing";
export const FREELANCING_DESCRIPTION = "Full Stack Web Developer";
export const FREELANCING_FROM = "JULY 19";
export const FREELANCING_TO = "FEB 20";
export const DEW_SOLUTIONS = "Dew Solution Pvt Ltd";
export const DEW_SOLUTIONS_DESCRIPTION = "Software Engineer";
export const DEW_SOLUTIONS_FROM = "FEB 20";
export const DEW_SOLUTIONS_TO = "Present";
export const TO = "to";

// Hobbies
export const ANIMATION = "3D Animations";
export const TRAVELLING = "Travelling";
export const MOVIES = "Movies and Series";
export const GAMING = "PC Gaming";

// Contact Details
export const NAME_LABEL = "Name:";
export const PHONE = "+91 xxxxxxxxxx";
export const EMAIL = "mannvender001&shy;@gmail";
export const ADDRESS = "Sector 45, Gurugram";
