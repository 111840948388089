import Email from 'components/Sharing/Email'
import Facebook from 'components/Sharing/Facebook'
import Twitter from 'components/Sharing/Twitter'
import Whatsapp from 'components/Sharing/Whatsapp'

export default {
	Email,
	Facebook,
	Twitter,
	Whatsapp,
}