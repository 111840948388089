export const NAME = "मानवेंद्र सिंह";
export const HI = "नमस्ते!";
export const IM = "मैं";
export const AGE = "23 + 4 वर्षीय";
export const INTRO_HELLO = "नमस्कार !!!";
export const INTRO_HEADING = "मैं नई दिल्ली, भारत में वेब डेवलपर हूँ";
export const INTRO_DESCRIPTION = `
मैं प्रोग्रामिंग से प्यार करता हूँ और मेरे कौशल को बेहतर बनाने के लिए चुनौतीपूर्ण कार्य और आत्म-अभिव्यक्ति के नए रूप कभी भी खोजना बंद नहीं करता`;
export const DEVELOPER = "डेवलपर";

// Section Headings
export const SKILLS_SECTION_HEADING = "कौशल";
export const LEARNING_SECTION_HEADING = "अनुभव";
export const RATING_SECTION_HEADING = "कौशल माप";
export const HOBBY_SECTION_HEADING = "शौक";
export const CONTACT_SECTION_HEADING = "संपर्क करें";

// Skills
export const DATA_STRUCTURES = "डेटा संरचना";
export const ALGORITHMS = "एल्गोरिदम";
export const JAVASCRIPT = "जावास्क्रिप्ट";
export const REACT = "रीऐक्ट जे.एस.";
export const NODE = "नोड जे.एस.";
export const GIT = "गिट";
export const MONGO_DB = "मोंगो डाटाबेस";
export const C = "सी प्रोग्रामिंग";
export const HTML_CSS = "एचटीएमएल/सीएसएस";
export const TYPESCRIPT = "टाइपस्क्रिप्ट";

// Timeline
export const CODING_BLOCKS = "कोडिंग ब्लॉक";
export const CODING_BLOCKS_DESCRIPTION = "मुझे प्रोग्रामिंग सिखाई";
export const CODING_BLOCKS_FROM = "सितम्बर 16";
export const CODING_BLOCKS_TO = "दिसम्बर 17";
export const SG_EDUTECH = "एसजी शिक्षा तकनीक प्राइवेट लि";
export const SG_EDUTECH_DESCRIPTION = "वेब डेवलपर के रूप में काम किया";
export const SG_EDUTECH_FROM = "जून 18";
export const SG_EDUTECH_TO = "अगस्त 18";
export const EDUATLAS = "शिक्षा एटलस";
export const EDUATLAS_DESCRIPTION = "फुल स्टैक वेब डेवलपर / सह-संस्थापक";
export const EDUATLAS_FROM = "अगस्त 18";
export const EDUATLAS_TO = "जुलाई 19";
export const FREELANCING = "प्रोफेशनल फ्रीलांसिंग";
export const FREELANCING_DESCRIPTION = "फुल स्टैक वेब डेवलपर";
export const FREELANCING_FROM = "जुलाई 19";
export const FREELANCING_TO = "फ़रवरी 20";
export const DEW_SOLUTIONS = "ड्यू सॉल्यूशन प्राइवेट लिमिटेड";
export const DEW_SOLUTIONS_DESCRIPTION = "सॉफ्टवेयर इंजीनियर";
export const DEW_SOLUTIONS_FROM = "फ़रवरी 20";
export const DEW_SOLUTIONS_TO = "अब तक";
export const TO = "से";

// Hobbies
export const ANIMATION = "3 डी एनिमेशन";
export const TRAVELLING = "यात्रा";
export const MOVIES = "फिल्में और टीवी शो";
export const GAMING = "कंप्यूटर गेमिंग";

// Contact Details
export const NAME_LABEL = "नाम:";
export const PHONE = "+91 xxxxxxxxxx";
export const EMAIL = "mannvender001@gmail";
export const ADDRESS = "सेक्टर 45, गुरुग्राम";
